const today = new Date();
const currentYear = today.getFullYear();
const loginYear = 2021;

export const usersModule = {
  labelsList: [
    {id: 1, name: "Banking", slug: "banking", color: "#FF8C00"},
    {id: 2, name: "Company", slug: "company", color: "#00C4B4"},
    {id: 3, name: "Payments", slug: "payments", color: "#0F9AF7"}
  ],
  usersList: [
    {
      id: 1,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 05:47:06",
      end_date: "2023-05-24 06:29:54",
      park_time: "42Min 48Sec",
      address: "No address",
      speed: "0",
      direction: "45",
      longitude: 153.213695,
      latitude: -27.7646066666667,
      monthlySales: 30000,
      yearlySales: 100000,
      area: "Brisbane",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU",
      active: true
    },
    {
      id: 2,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 07:16:11",
      end_date: "2023-05-24 07:20:41",
      park_time: "4Min 30Sec",
      address: "No address",
      speed: "0",
      direction: "315",
      longitude: 153.023666666667,
      latitude: -27.601415,
      monthlySales: 40000,
      yearlySales: 110000,
      area: "Queensland",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 3,

      license_plate: "10 PMF_OBD",
      start_date: "2023-05-24 07:26:52",
      end_date: "2023-05-24 07:29:52",
      park_time: "3Min ",
      address: "No address",
      speed: "0",
      direction: "225",
      longitude: 153.027158333333,
      latitude: -27.604385,
      monthlySales: 50000,
      yearlySales: 120000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 4,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 07:31:22",
      end_date: "2023-05-24 07:37:12",
      park_time: "5Min 50Sec",
      address: "No address",
      speed: "0",
      direction: "225",
      longitude: 153.02699,
      latitude: -27.6044533333333,
      monthlySales: 60000,
      yearlySales: 130000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 5,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 07:51:52",
      end_date: "2023-05-24 07:57:12",
      park_time: "5Min 20Sec",
      address: "No address",
      speed: "0",
      direction: "225",
      longitude: 152.972136666667,
      latitude: -27.5736633333333,
      monthlySales: 70000,
      yearlySales: 140000,
      area: "Brisbane",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 6,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 08:02:22",
      end_date: "2023-05-24 08:07:22",
      park_time: "5Min ",
      address: "No address",
      speed: "0",
      direction: "135",
      longitude: 152.998203333333,
      latitude: -27.5590983333333,
      monthlySales: 80000,
      yearlySales: 150000,
      area: "Sydney",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 7,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 08:11:32",
      end_date: "2023-05-24 08:21:31",
      park_time: "9Min 59Sec",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.007845,
      latitude: -27.55179,
      monthlySales: 90000,
      yearlySales: 160000,
      area: "Sydney",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 8,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 08:31:52",
      end_date: "2023-05-24 08:38:11",
      park_time: "6Min 19Sec",
      address: "No address",
      speed: "0",
      direction: "315",
      longitude: 152.965848333333,
      latitude: -27.5745533333333,
      monthlySales: 100000,
      yearlySales: 170000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 9,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 08:48:22",
      end_date: "2023-05-24 08:55:02",
      park_time: "6Min 40Sec",
      address: "No address",
      speed: "0",
      direction: "45",
      longitude: 152.938481666667,
      latitude: -27.6215116666667,
      monthlySales: 110000,
      yearlySales: 180000,
      area: "Sydney",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 10,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 08:55:52",
      end_date: "2023-05-24 09:02:02",
      park_time: "6Min 10Sec",
      address: "No address",
      speed: "0",
      direction: "135",
      longitude: 152.9376,
      latitude: -27.6231583333333,
      monthlySales: 120000,
      yearlySales: 190000,
      area: "Brisbane",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 11,

      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 09:04:02",
      end_date: "2023-05-24 09:10:22",
      park_time: "6Min 20Sec",
      address: "No address",
      speed: "0",
      direction: "315",
      longitude: 152.927925,
      latitude: -27.621625,
      monthlySales: 130000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 12,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 09:14:32",
      end_date: "2023-05-24 09:25:02",
      park_time: "10Min 30Sec",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 152.923935,
      latitude: -27.6178483333333,
      monthlySales: 140000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 13,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 09:28:32",
      end_date: "2023-05-24 09:33:12",
      park_time: "4Min 40Sec",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 152.938273333333,
      latitude: -27.6216533333333,
      monthlySales: 150000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 14,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 09:35:22",
      end_date: "2023-05-24 09:42:02",
      park_time: "6Min 40Sec",
      address: "No address",
      speed: "0",
      direction: "315",
      longitude: 152.932338333333,
      latitude: -27.62191,
      monthlySales: 160000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 15,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 10:00:32",
      end_date: "2023-05-24 10:07:32",
      park_time: "7Min ",
      address: "No address",
      speed: "0",
      direction: "315",
      longitude: 152.995166666667,
      latitude: -27.5634633333333,
      monthlySales: 170000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 16,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 10:10:32",
      end_date: "2023-05-24 10:24:02",
      park_time: "13Min 30Sec",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.004441666667,
      latitude: -27.56647,
      monthlySales: 180000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 17,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 10:31:32",
      end_date: "2023-05-24 10:40:22",
      park_time: "8Min 50Sec",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 152.962725,
      latitude: -27.5653766666667,
      monthlySales: 190000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 18,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 10:55:52",
      end_date: "2023-05-24 11:00:12",
      park_time: "4Min 20Sec",
      address: "No address",
      speed: "0",
      direction: "315",
      longitude: 153.023598333333,
      latitude: -27.6014116666667,
      monthlySales: 200000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 19,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 11:10:32",
      end_date: "2023-05-24 11:13:32",
      park_time: "3Min ",
      address: "No address",
      speed: "0",
      direction: "45",
      longitude: 153.012976666667,
      latitude: -27.621485,
      monthlySales: 210000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 20,
      license_plate: "08 PMF_OBD",
      start_date: "2023-05-24 11:21:32",
      end_date: "2023-05-24 11:26:32",
      park_time: "5Min ",
      address: "No address",
      speed: "0",
      direction: "315",
      longitude: 153.001728333333,
      latitude: -27.6160916666667,
      monthlySales: 220000,
      yearlySales: 200000,
      area: "NSW",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 21,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 08:15:00",
      end_date: "2023-06-11 08:45:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.025817,
      latitude: -27.467839,
      monthlySales: 35000,
      yearlySales: 120000,
      area: "Brisbane",
      profilePic: "https://example.com/profile1.jpg",
      active: true
    },
    {
      id: 22,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 09:00:00",
      end_date: "2023-06-11 09:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 152.989167,
      latitude: -27.532163,
      monthlySales: 32000,
      yearlySales: 105000,
      area: "Brisbane",
      profilePic: "https://example.com/profile2.jpg"
    },
    {
      id: 23,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 09:45:00",
      end_date: "2023-06-11 10:15:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.051355,
      latitude: -27.483876,
      monthlySales: 28000,
      yearlySales: 95000,
      area: "Brisbane",
      profilePic: "https://example.com/profile3.jpg"
    },
    {
      id: 24,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 10:30:00",
      end_date: "2023-06-11 11:00:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 152.972414,
      latitude: -27.566192,
      monthlySales: 30000,
      yearlySales: 100000,
      area: "Brisbane",
      profilePic: "https://example.com/profile4.jpg"
    },
    {
      id: 25,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 11:15:00",
      end_date: "2023-06-11 11:45:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.017759,
      latitude: -27.514477,
      monthlySales: 38000,
      yearlySales: 140000,
      area: "Brisbane",
      profilePic: "https://example.com/profile5.jpg"
    },
    {
      id: 26,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 12:00:00",
      end_date: "2023-06-11 12:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.050869,
      latitude: -27.430088,
      monthlySales: 32000,
      yearlySales: 105000,
      area: "Brisbane",
      profilePic: "https://example.com/profile6.jpg"
    },
    {
      id: 27,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 12:45:00",
      end_date: "2023-06-11 13:15:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.031195,
      latitude: -27.459357,
      monthlySales: 29000,
      yearlySales: 98000,
      area: "Brisbane",
      profilePic: "https://example.com/profile7.jpg"
    },
    {
      id: 28,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 13:30:00",
      end_date: "2023-06-11 14:00:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.066905,
      latitude: -27.443197,
      monthlySales: 33000,
      yearlySales: 110000,
      area: "Brisbane",
      profilePic: "https://example.com/profile8.jpg"
    },
    {
      id: 29,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 14:15:00",
      end_date: "2023-06-11 14:45:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.101712,
      latitude: -27.486979,
      monthlySales: 30000,
      yearlySales: 100000,
      area: "Brisbane",
      profilePic: "https://example.com/profile9.jpg"
    },
    {
      id: 30,
      license_plate: "09 PMF_OBD",
      start_date: "2023-06-11 15:00:00",
      end_date: "2023-06-11 15:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.087346,
      latitude: -27.534152,
      monthlySales: 31000,
      yearlySales: 102000,
      area: "Brisbane",
      profilePic: "https://example.com/profile10.jpg"
    },
    {
      id: 31,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 05:47:06",
      end_date: "2023-06-11 06:29:54",
      park_time: "42Min 48Sec",
      address: "No address",
      speed: "0",
      direction: "45",
      longitude: 153.219283,
      latitude: -27.441827,
      monthlySales: 30000,
      yearlySales: 100000,
      area: "Brisbane",
      profilePic:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0K6RXK-zODNnY_6R5Qsw81sk4UYQQZvfHqsHrncUdNru8iKyoDAXpapCYup5AgV1Ciyo&usqp=CAU"
    },
    {
      id: 32,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 07:00:00",
      end_date: "2023-06-11 07:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.206624,
      latitude: -27.439495,
      monthlySales: 31000,
      yearlySales: 103000,
      area: "Brisbane",
      profilePic: "https://example.com/profile21.jpg",
      active: true
    },
    {
      id: 33,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 08:00:00",
      end_date: "2023-06-11 08:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.19383,
      latitude: -27.428522,
      monthlySales: 32000,
      yearlySales: 106000,
      area: "Brisbane",
      profilePic: "https://example.com/profile22.jpg"
    },
    {
      id: 34,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 09:00:00",
      end_date: "2023-06-11 09:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.194862,
      latitude: -27.404784,
      monthlySales: 33000,
      yearlySales: 109000,
      area: "Brisbane",
      profilePic: "https://example.com/profile23.jpg"
    },
    {
      id: 35,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 10:00:00",
      end_date: "2023-06-11 10:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.215905,
      latitude: -27.38128,
      monthlySales: 34000,
      yearlySales: 112000,
      area: "Brisbane",
      profilePic: "https://example.com/profile24.jpg"
    },
    {
      id: 36,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 11:00:00",
      end_date: "2023-06-11 11:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.233326,
      latitude: -27.37647,
      monthlySales: 35000,
      yearlySales: 115000,
      area: "Brisbane",
      profilePic: "https://example.com/profile25.jpg"
    },
    {
      id: 37,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 12:00:00",
      end_date: "2023-06-11 12:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.254493,
      latitude: -27.383976,
      monthlySales: 36000,
      yearlySales: 118000,
      area: "Brisbane",
      profilePic: "https://example.com/profile26.jpg"
    },
    {
      id: 38,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 13:00:00",
      end_date: "2023-06-11 13:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.26424,
      latitude: -27.400702,
      monthlySales: 37000,
      yearlySales: 121000,
      area: "Brisbane",
      profilePic: "https://example.com/profile27.jpg"
    },
    {
      id: 39,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 14:00:00",
      end_date: "2023-06-11 14:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.266759,
      latitude: -27.42308,
      monthlySales: 38000,
      yearlySales: 124000,
      area: "Brisbane",
      profilePic: "https://example.com/profile28.jpg"
    },
    {
      id: 40,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 15:00:00",
      end_date: "2023-06-11 15:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.262689,
      latitude: -27.447352,
      monthlySales: 39000,
      yearlySales: 127000,
      area: "Brisbane",
      profilePic: "https://example.com/profile29.jpg"
    },
    {
      id: 41,
      license_plate: "10 PMF_OBD",
      start_date: "2023-06-11 16:00:00",
      end_date: "2023-06-11 16:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.245676,
      latitude: -27.474027,
      monthlySales: 40000,
      yearlySales: 130000,
      area: "Brisbane",
      profilePic: "https://example.com/profile30.jpg"
    },

    {
      id: 42,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 10:00:00",
      end_date: "2023-06-11 10:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.215905,
      latitude: -27.37647,
      monthlySales: 42000,
      yearlySales: 136000,
      area: "Brisbane",
      profilePic: "https://example.com/profile32.jpg",
      active: true
    },
    {
      id: 43,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 11:00:00",
      end_date: "2023-06-11 11:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.231826,
      latitude: -27.388913,
      monthlySales: 43000,
      yearlySales: 139000,
      area: "Brisbane",
      profilePic: "https://example.com/profile33.jpg"
    },
    {
      id: 44,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 12:00:00",
      end_date: "2023-06-11 12:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.246932,
      latitude: -27.401194,
      monthlySales: 44000,
      yearlySales: 142000,
      area: "Brisbane",
      profilePic: "https://example.com/profile34.jpg"
    },
    {
      id: 45,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 13:00:00",
      end_date: "2023-06-11 13:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.26444,
      latitude: -27.422315,
      monthlySales: 45000,
      yearlySales: 145000,
      area: "Brisbane",
      profilePic: "https://example.com/profile35.jpg"
    },
    {
      id: 46,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 14:00:00",
      end_date: "2023-06-11 14:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.249918,
      latitude: -27.44315,
      monthlySales: 46000,
      yearlySales: 148000,
      area: "Brisbane",
      profilePic: "https://example.com/profile36.jpg"
    },
    {
      id: 47,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 15:00:00",
      end_date: "2023-06-11 15:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.263295,
      latitude: -27.459182,
      monthlySales: 47000,
      yearlySales: 151000,
      area: "Brisbane",
      profilePic: "https://example.com/profile37.jpg"
    },
    {
      id: 48,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 16:00:00",
      end_date: "2023-06-11 16:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.244655,
      latitude: -27.476997,
      monthlySales: 48000,
      yearlySales: 154000,
      area: "Brisbane",
      profilePic: "https://example.com/profile38.jpg"
    },
    {
      id: 49,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 17:00:00",
      end_date: "2023-06-11 17:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.225763,
      latitude: -27.469311,
      monthlySales: 49000,
      yearlySales: 157000,
      area: "Brisbane",
      profilePic: "https://example.com/profile39.jpg"
    },
    {
      id: 50,
      license_plate: "11 PMF_OBD",
      start_date: "2023-06-11 18:00:00",
      end_date: "2023-06-11 18:30:00",
      park_time: "30Min",
      address: "No address",
      speed: "0",
      direction: "0",
      longitude: 153.218794,
      latitude: -27.462808,
      monthlySales: 50000,
      yearlySales: 160000,
      area: "Brisbane",
      profilePic: "https://example.com/profile40.jpg"
    }
  ]
};
