import React, {useEffect, useState} from "react";
import {compose, withProps} from "recompose";
import {IconButton} from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import fetch from "isomorphic-fetch";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  life,
  InfoWindow
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import {Box, Divider, SvgIcon, Typography} from "@material-ui/core";
import StandaloneSearchBox from "react-google-maps/lib/components/places/StandaloneSearchBox";
import PropertiesStatistics from "routes/Dashboards/Listing/PropertiesStatistics";
import DealsAnalytics from "routes/Dashboards/Listing/DealsAnalytics";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import CmtAvatar from "@coremat/CmtAvatar";
import {usersModule} from "@fake-db/modules/users";
const google = window.google;

const PopupComponent = data => {
  const history = useHistory();

  return (
    <Box>
      <Box p={3}>
        <Typography component="div" variant="h5" ml={2}>
          Plate:
          <Box component="span" color="primary.main" ml={2}>
            {data["license_plate"]}
          </Box>
        </Typography>
      </Box>
      <Box p={3}>
        <Typography component="div" variant="h5">
          Current Sales:
          <Box component="span" color="primary.main" ml={2}>
            {data["monthlySales"]}
          </Box>
        </Typography>
      </Box>
      <Box p={3}>
        <Typography component="div" variant="h6">
          Area:
          <Box component="span" color="primary.main" ml={2}>
            {data["area"]}
          </Box>
        </Typography>
      </Box>
      <Divider />

      <Box display="flex" sx={{justifyContent: "flex-end"}} p={3}>
        <Box
          component="span"
          color="primary.main"
          ml={2}
          fontSize={14}
          onClick={() => history.push("/dashboard/truck")}
        >
          View Details
        </Box>
      </Box>
    </Box>
  );
};

const MarkerClustererExampleGoogleMap =
  // compose(
  compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,geometry,drawing&key=AIzaSyDCMVXgK_GBqHNhbnqx4CoeeHPCc7Yoos8",
      loadingElement: <div style={{height: `100%`}} />,
      containerElement: <div style={{height: `600px`}} />,
      mapElement: <div style={{height: `100%`}} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => {
    // const onLoad = (map) => {
    //   console.log("masuk");
    //   setMap(map);
    // };

    return (
      <GoogleMap
        // defaultZoom={props.zoomLevel}
        zoom={props.zoomLevel}
        // defaultCenter={{ lat: -27.7646066666667, lng: 153.213695 }}
        // center={props.center}
        ref={props.onMapLoad}
        // onLoad={onLoad}
        // isMarkerShown={true}
      >
        {/* <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}> */}
        {props.markers.map((marker, index) => (
          // < key={marker.id}>
          <Marker
            position={{
              lat: marker.latitude,
              lng: marker.longitude
            }}
            // label={marker.license_plate}
            key={marker.id}
            // position={marker.position}
            // defaultIcon={"/images/marker.png"}

            onClick={() => props.onMarkerClick(marker)}
            animation={
              marker && marker.active ? (
                window.google.maps.Animation.BOUNCE
              ) : (
                window.google.maps.Animation.DROP
              )
            }
          >
            {marker.showInfo && (
              <InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
                <Box>
                  <PopupComponent {...marker} />
                </Box>
              </InfoWindow>
            )}
          </Marker>
        ))}
        {/* </MarkerClusterer> */}
        {/* <Marker position={{ lat: -27.7646066666667, lng: 153.213695 }} /> */}
      </GoogleMap>
    );
  });

const MarkerClustererExample = ({searchTerm, refs, zoomLevel}) => {
  const history = useHistory();
  const [ changeFilter, setChangeFilter ] = useState(false);
  const [ markers, setMarkers ] = useState(usersModule.usersList);

  const [ filteredMarkers, setFilteredMarkers ] = useState([]);

  useEffect(
    () => {
      if (searchTerm && searchTerm !== "") {
        setFilteredMarkers(() => {
          return markers.filter(
            marker =>
              marker["license_plate"]
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              marker.monthlySales
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              marker.yearlySales
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              marker.area
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          );
        });
      } else {
        setFilteredMarkers(markers);
      }
      setChangeFilter(true);
    },
    [ searchTerm ]
  );

  // Toggle to 'true' to show InfoWindow and re-renders simple
  const handleMarkerClick = targetMarker => {
    console.log(targetMarker, "marker");
    setChangeFilter(false);
    setFilteredMarkers(() => {
      return filteredMarkers.map(marker => {
        if (marker.id === targetMarker.id) {
          // if (refs.current) {
          //   const bounds = new window.google.maps.LatLngBounds();
          //   bounds.extend({ lat: marker.latitude, lng: marker.longitude });

          //   setBounds(bounds);
          //   refs.current.fitBounds(bounds); //
          // }

          return {
            ...marker,
            showInfo: true
          };
        } else {
          return {
            ...marker,
            showInfo: false
          };
        }
      });
    });
  };

  const handleMapLoad = map => {
    refs.current = map;
  };

  const handleMarkerClose = targetMarker => {
    setChangeFilter(false);
    setFilteredMarkers(() => {
      return filteredMarkers.map(marker => {
        if (marker.id === targetMarker.id) {
          return {
            ...marker,
            showInfo: false
          };
        }
        // if (refs.current) {
        //   const bounds = new window.google.maps.LatLngBounds();
        //   filteredMarkers.forEach((point) => {
        //     bounds.extend({ lat: point.latitude, lng: point.longitude });
        //   });
        //   setBounds(bounds);
        //   console.log(bounds, "bounds");
        //   refs.current.fitBounds(bounds); //
        // }
        return marker;
      });
    });
  };

  const [ bounds, setBounds ] = useState(null);
  const [ map, setMap ] = useState(null);
  useEffect(
    () => {
      if (refs.current && filteredMarkers.length > 0 && changeFilter) {
        console.log("masuk bila change");
        const bounds = new window.google.maps.LatLngBounds();
        filteredMarkers.forEach(point => {
          bounds.extend({lat: point.latitude, lng: point.longitude});
        });
        setBounds(bounds);
        console.log(bounds, "bounds");
        refs.current.fitBounds(bounds); // Fit the map to the bounds
      }
    },
    [ filteredMarkers, refs.current, changeFilter ]
  );

  return (
    <MarkerClustererExampleGoogleMap
      loadingElement={<Box height={1} />}
      containerElement={<Box height={{xs: 300, sm: 400}} />}
      mapElement={<Box height={1} />}
      markers={filteredMarkers}
      onMarkerClick={handleMarkerClick}
      // center={{
      //   lat: -27.7646066666667,
      //   lng: 153.213695,
      // }}
      onMarkerClose={handleMarkerClose}
      onMapLoad={handleMapLoad}
      zoomLevel={zoomLevel}
    />
  );
};

export default MarkerClustererExample;
