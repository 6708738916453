import Box from "@material-ui/core/Box";
import React from "react";
import {getCustomDateTime} from "../../@jumbo/utils/dateHelper";
import {Link} from "react-router-dom";

export const statisticsGraphData = [
  {month: "Jan", price: 200},
  {month: "Feb", price: 300},
  {month: "Mar", price: 550},
  {month: "Apr", price: 500},
  {month: "May", price: 700},
  {month: "Jun", price: 450},
  {month: "Jul", price: 770},
  {month: "Aug", price: 900}
];

export const dealsAnalyticsData = [
  {month: "Jan", queries: 400, deals: 400},
  {month: "Feb", queries: 500, deals: 600},
  {month: "Mar", queries: 400, deals: 300},
  {month: "Apr", queries: 350, deals: 200},
  {month: "May", queries: 700, deals: 700},
  {month: "Jun", queries: 100, deals: 600},
  {month: "Jul", queries: 500, deals: 50},
  {month: "Aug", queries: 350, deals: 550},
  {month: "Sep", queries: 300, deals: 200},
  {month: "Oct", queries: 200, deals: 500},
  {month: "Nov", queries: 200, deals: 600},
  {month: "Dec", queries: 200, deals: 100}
];

export const TruckSalesByDistrict = [
  // attribute name is district at sydney
  {district: "Sydney", sales: 200},
  {district: "Melbourne", sales: 300},
  {district: "Brisbane", sales: 550},
  {district: "Perth", sales: 500},
  {district: "Adelaide", sales: 700},
  {district: "Gold Coast", sales: 450},
  {district: "Newcastle", sales: 770},
  {district: "Canberra", sales: 900}
];

export const popularAgents = [
  {
    id: 1,
    name: "Albert Hall",
    deals: 23,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 3.5,
    profileCompleted: 10
  },
  {
    id: 2,
    name: "John Hall",
    deals: 20,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 4.5,
    profileCompleted: 10
  },
  {
    id: 3,
    name: "Jackson Hall",
    deals: 21,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 3.5,
    profileCompleted: 10
  },
  {
    id: 4,
    name: "Jonty Hall",
    deals: 22,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 4.5,
    profileCompleted: 10
  },
  {
    id: 5,
    name: "Jonathan Hall",
    deals: 23,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 3.5,
    profileCompleted: 10
  },
  {
    id: 6,
    name: "Shane Hall",
    deals: 24,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 4.5,
    profileCompleted: 10
  },
  {
    id: 7,
    name: "Lisa Hall",
    deals: 25,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 3.5,
    profileCompleted: 10
  },
  {
    id: 8,
    name: "Cheeni Hall",
    deals: 26,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 4.5,
    profileCompleted: 10
  },
  {
    id: 9,
    name: "Chilbram Hall",
    deals: 27,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 3.5,
    profileCompleted: 10
  },
  {
    id: 10,
    name: "Danny Hall",
    deals: 83,
    profilePic: "https://via.placeholder.com/150x150",
    rating: 4.5,
    profileCompleted: 10
  }
];

export const propertyTabCategories = [
  {name: "New Jersey", slug: "new_jersey"},
  {name: "Colorado", slug: "colorado"},
  {name: "Albama", slug: "albama"}
];

export const propertiesList = [
  {
    id: 1,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Luxury family home at beach side",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 26, 2020",
    availability: "sale",
    isTrending: true,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "new_jersey"
  },
  {
    id: 2,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Sunset view Apartment in Colarado",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 25, 2020",
    availability: "rent",
    isTrending: false,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "colorado"
  },
  {
    id: 3,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Best property in Albama",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 23, 2020",
    availability: "rent",
    isTrending: false,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "albama"
  },
  {
    id: 4,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Best house deal in New jersey",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 24, 2020",
    availability: "sale",
    isTrending: false,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "new_jersey"
  },
  {
    id: 5,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Luxury apartment in Colarado",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 28, 2020",
    availability: "rent",
    isTrending: true,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "colorado"
  },
  {
    id: 6,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Plot in Albama",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 29, 2020",
    availability: "sale",
    isTrending: true,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "albama"
  },
  {
    id: 7,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "House in New jersey",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 24, 2020",
    availability: "sale",
    isTrending: false,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "new_jersey"
  },
  {
    id: 8,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Flat in Colarado",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 20, 2020",
    availability: "rent",
    isTrending: true,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "colorado"
  },
  {
    id: 9,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "3 BHK house in Albama",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 28, 2020",
    availability: "sale",
    isTrending: false,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "albama"
  },
  {
    id: 10,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Best house for family in New Jersey",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 26, 2020",
    availability: "rent",
    isTrending: true,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "new_jersey"
  },
  {
    id: 11,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Villa in Colarado",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 16, 2020",
    availability: "rent",
    isTrending: true,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "colorado"
  },
  {
    id: 12,
    images: [
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 1"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 2"
      },
      {
        image: "https://via.placeholder.com/640x420",
        title: "image 3"
      }
    ],
    title: "Sunrise view apartment in Albama",
    address: "2972, Washington Road, New Jersey",
    bedrooms: 3,
    bathrooms: 3,
    area: "1400 m2",
    owner: {id: 1, name: "John Nash"},
    publishedDate: "June 28, 2020",
    availability: "sale",
    isTrending: false,
    price: "$670,500",
    pricePerSqFt: "$587/sqft",
    category: "albama"
  }
];

export const recentActivities = [
  {
    id: 1,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: " 08 PMF_OBD",
      profilePic: "https://via.placeholder.com/150x150"
    },
    mediaList: [],
    content: [
      "Truck ",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 burger"
    ]
  },
  {
    id: 2,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: " 08 PMF_OBD",
      profilePic: "https://via.placeholder.com/150x150"
    },
    mediaList: [],
    content: [
      "Truck ",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 burger"
    ]
  },
  {
    id: 3,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic: "https://via.placeholder.com/150x150"
    },
    mediaList: [],
    content: [
      "Truck ",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 burger"
    ]
  },
  {
    id: 4,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic: "https://via.placeholder.com/150x150"
    },
    mediaList: [],
    content: [
      "Truck ",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 burger"
    ]
  },
  {
    id: 5,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic: "https://via.placeholder.com/150x150"
    },
    mediaList: [],
    content: [
      "Truck ",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 burger"
    ]
  },
  {
    id: 6,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic: "https://via.placeholder.com/150x150"
    },
    mediaList: [],
    content: [
      "Truck ",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 burger"
    ]
  },
  {
    id: 7,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic: "https://via.placeholder.com/150x150"
    },
    mediaList: [],
    content: [
      "Truck ",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 burger"
    ]
  }
];

export const recentTruckActivities = [
  {
    id: 1,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: " 08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/07/carles-rabada-710128-unsplash-e1532247032829.jpg"
    },
    mediaList: [],
    content: [ "Hamburger - 523 units" ]
  },
  {
    id: 2,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: " 08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/07/eaters-collective-197218-unsplash-e1532246659510.jpg"
    },
    mediaList: [],
    content: [ "Lettuce, Cheese, Tomato Roll - 512 units" ]
  },
  {
    id: 3,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/07/brian-chan-12169-unsplash-e1532254812231.jpg"
    },
    mediaList: [],
    content: [ "Chicken Wingdings - 490 units" ]
  },
  {
    id: 5,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/08/anna-sullivan-687938-unsplash-e1533287770558.jpg"
    },
    mediaList: [],
    content: [ "Lolly Bags / Extra / Mentos - 480 units" ]
  }
];

export const recentTruckActivitiesReports = [
  {
    id: 1,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: " 08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/07/carles-rabada-710128-unsplash-e1532247032829.jpg"
    },
    mediaList: [],
    content: [
      "Truck 08 PMF_OBD",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 hamburger"
    ]
  },
  {
    id: 2,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: " 08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/07/eaters-collective-197218-unsplash-e1532246659510.jpg"
    },
    mediaList: [],
    content: [
      "Truck 09 PMF_OBD",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 5 Lettuce, Cheese, Tomato Roll"
    ]
  },
  {
    id: 3,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/07/brian-chan-12169-unsplash-e1532254812231.jpg"
    },
    mediaList: [],
    content: [
      "Truck 10 PMF_OBD",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 10 Chicken Wingdings"
    ]
  },
  {
    id: 5,
    date: getCustomDateTime(0, "days", "MMM DD, YYYY"),
    user: {
      id: 12,
      name: "08 PMF_OBD",
      profilePic:
        "https://premiermobilefood.com.au/wp-content/uploads/2018/08/anna-sullivan-687938-unsplash-e1533287770558.jpg"
    },
    mediaList: [],
    content: [
      "Truck 10 PMF_OBD",
      <Link
        to="/dashboard/truck"
        key="component-1"
        color="primary.main"
        className="pointer"
        mr={1}
      >
        08 PMF_OBD
      </Link>,
      " sell 100 Lolly Bags / Extra / Mentos"
    ]
  }
];
export const newCustomers = [
  {
    id: 1,
    name: "Albert Hall",
    profilePic: "https://via.placeholder.com/150x150"
  },
  {
    id: 2,
    name: "John Hall",
    profilePic: "https://via.placeholder.com/150x150"
  },
  {id: 3, name: "Jackson Hall", profilePic: ""},
  {
    id: 4,
    name: "Jonty Hall",
    profilePic: "https://via.placeholder.com/150x150"
  },
  {
    id: 5,
    name: "Jonathan Hall",
    profilePic: "https://via.placeholder.com/150x150"
  },
  {
    id: 6,
    name: "Shane Hall",
    profilePic: "https://via.placeholder.com/150x150"
  },
  {
    id: 7,
    name: "Lisa Hall",
    profilePic: "https://via.placeholder.com/150x150"
  },
  {id: 8, name: "Cheeni Hall", profilePic: ""},
  {
    id: 9,
    name: "Chilbram Hall",
    profilePic: "https://via.placeholder.com/150x150"
  },
  {
    id: 10,
    name: "Danny Hall",
    profilePic: "https://via.placeholder.com/150x150"
  }
];
