import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import {timeFromNow} from "../../../../@jumbo/utils/dateHelper";
import {
  Block,
  CheckCircleOutline,
  Delete,
  Edit,
  Mail,
  MoreHoriz,
  Visibility
} from "@material-ui/icons";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {
  sentMailToUser,
  updateUserStatus
} from "../../../../redux/actions/Users";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark
  }
}));

const getUserActions = user => {
  const actions = [
    {action: "view", label: "View", icon: <Visibility />},
    {action: "zoom", label: "Zoom To", icon: <Edit />}
    // { action: "email", label: "Email", icon: <Mail /> },
  ];

  // if (user.status === "active") {
  //   actions.push({ action: "suspend", label: "Suspend", icon: <Block /> });
  // } else {
  //   actions.push({
  //     action: "activate",
  //     label: "Reactivate",
  //     icon: <CheckCircleOutline />,
  //   });
  // }

  actions.push({action: "delete", label: "Delete", icon: <Delete />});
  return actions;
};

const UserListRow = ({
  row,
  isSelected,
  onRowClick,
  onUserEdit,
  onUserDelete,
  onUserView,
  refs,
  setZoomLevel
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === "view") {
      // onUserView(row);
      history.push("/dashboard/truck");
    } else if (menu.action === "zoom") {
      console.log(row, "row");
      console.log(refs, "row");
      if (refs && refs.current) {
        setZoomLevel(19);
        refs.current.panTo({
          lat: row.latitude,
          lng: row.longitude
        });
      }
      // onUserEdit(row);
    } else if (menu.action === "email") {
      dispatch(sentMailToUser());
    } else if (menu.action === "suspend") {
      dispatch(updateUserStatus({id: row.id, status: "suspended"}));
    } else if (menu.action === "activate") {
      dispatch(updateUserStatus({id: row.id, status: "active"}));
    } else if (menu.action === "delete") {
      onUserDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row.id}`;
  const isItemSelected = isSelected(row.id);
  const userActions = getUserActions(row);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {/* <TableCell padding="checkbox">
        <Checkbox
          checked={isItemSelected}
          inputProps={{ "aria-labelledby": labelId }}
        />
      </TableCell> */}
      <TableCell
        component="th"
        id={labelId}
        scope="row"
        padding="checkbox"
        style={{padding: 20}}
      >
        <Box display="flex" alignItems="center">
          <div>
            <Typography
              className={classes.titleRoot}
              component="div"
              variant="h4"
            >
              {row.license_plate}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.monthlySales}</TableCell>
      <TableCell>{row.yearlySales}</TableCell>
      {/* <TableCell>
        {row.status === "suspended"
          ? `Suspended by ${row.suspendedBy} (${timeFromNow(row.suspendedAt)})`
          : row.status}
      </TableCell> */}
      <TableCell>{row.area}</TableCell>

      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu
          items={userActions}
          onItemClick={onUserMenuClick}
          TriggerComponent={<MoreHoriz />}
        />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
